body {
    --text-color1: rgba(0, 0, 0, 0.87);
    --bg1: #f9f9fa;
    --bg2: #fcfcfc;
    --bg3: rgb(252, 252, 252);
    --bg4: #FFFFFF;
    --toggle-button: rgba(109, 109, 109, 0.1);
    --linearProgress: #B2C7FFFF;
    --linearProgress-bar: #366eff;
    --overlay-color: rgba(252, 252, 252, 0.8);
    background-color: var(--bg2);
    --backcolor: rgb(182, 33, 33)
}

* {
    font-family: 'Montserrat', sans-serif !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-gutter: auto;
}
